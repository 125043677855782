/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import type { ExtNode } from "relatives-tree/lib/types";
import ReactFamilyTree from "react-family-tree";
import { PinchZoomPan } from "../../components/PinchZoomPan/PinchZoomPan";
import { FamilyNode } from "../../components/FamilyNode/FamilyNode";
import { NodeDetails } from "../../components/NodeDetails/NodeDetails";
import { NODE_WIDTH, NODE_HEIGHT } from "../../components/const";
import { getNodeStyle } from "./utils";

import css from "./family.module.css";
import { useSearchParams } from "react-router-dom";
import Service from "../../services/api";
import { GlobalContext } from "../../context/GlobalContext";

export default React.memo(function View(props: any) {
  // const nodes = SOURCES[DEFAULT_SOURCE];
  const [nodes, setNodes] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const [selectId, setSelectId] = useState<string>();
  const [hoverId, setHoverId] = useState<string>();
  const [profile, setProfile] = useState(null);

  const userId = searchParams.get("userId");

  const firstNodeId = useMemo(() => {
    if (nodes && nodes.length > 0) return nodes[0].id;
    return "";
  }, [nodes]);

  const [rootId, setRootId] = useState(firstNodeId);
  const { getLocations } = useContext(GlobalContext);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  const getData = async () => {
    try {
      const res: any = await Service.getProfileById(userId as string);
      if (res && res.user) {
        const _profile = {
          ...res.user,
          ...res.user.profile,
        };
        setProfile(_profile);
        setRootId(res.user.refer_code);
        const relations: any = await Service.getRelations({
          params: {
            userId,
          },
        });
        if (relations && relations.length > 0) groupData(relations);
        else
          setNodes([
            {
              ..._profile,
              id: _profile.refer_code,
              parents: [],
              siblings: [],
              spouses: [],
              children: [],
            },
          ]);
      }
    } catch (error) {}
  };

  const groupData = (data: []) => {
    let _nodes: any[] = [];
    let listUser: any[] = [];
    data.forEach((r: any) => {
      if (!listUser.some((l: any) => l.from_id === r.from_id)) listUser.push(r);
    });
    listUser.forEach((user) => {
      const parents = data
        .filter(
          (r: any) => r.from_id === user.from_id && r.relation === "parents"
        )
        .map((r: any) => ({
          ...r.to,
          id: r.to.refer_code,
          type: "blood",
        }));
      const siblings = data
        .filter(
          (r: any) => r.from_id === user.from_id && r.relation === "siblings"
        )
        .map((r: any) => ({
          ...r.to,
          id: r.to.refer_code,
          type: "blood",
        }));
      const spouses = data
        .filter(
          (r: any) => r.from_id === user.from_id && r.relation === "spouses"
        )
        .map((r: any) => ({
          ...r.to,
          id: r.to.refer_code,
          type: "married",
        }));
      const children = data
        .filter(
          (r: any) => r.from_id === user.from_id && r.relation === "children"
        )
        .map((r: any) => ({
          ...r.to,
          id: r.to.refer_code,
          type: "blood",
        }));
      _nodes.push({
        ...user.from,
        ...user.profile,
        user_id: user.from_id,
        id: user.from.refer_code,
        parents,
        siblings,
        spouses,
        children,
      });
    });
    setNodes(() => _nodes);
  };

  const resetRootHandler = useCallback(
    () => setRootId(firstNodeId),
    [firstNodeId]
  );

  const selected = useMemo(
    () => nodes.find((item: any) => item.id === selectId),
    [nodes, selectId]
  );

  return (
    <div className={css.root}>
      {nodes && nodes.length > 0 && rootId && (
        <PinchZoomPan min={0.5} max={5} captureWheel className={css.wrapper}>
          <ReactFamilyTree
            nodes={nodes}
            rootId={rootId}
            width={NODE_WIDTH}
            height={NODE_HEIGHT}
            className={css.tree}
            renderNode={(node: Readonly<ExtNode>) => (
              <FamilyNode
                key={node.id}
                node={node}
                isRoot={node.id === rootId}
                isHover={node.id === hoverId}
                profile={profile}
                onClick={setSelectId}
                onSubClick={setRootId}
                style={getNodeStyle(node)}
              />
            )}
          />
        </PinchZoomPan>
      )}
      {rootId !== firstNodeId && (
        <button className={css.reset} onClick={resetRootHandler}>
          Reset
        </button>
      )}
      {selected && (
        <NodeDetails
          node={selected}
          myProfile={profile}
          className={css.details}
          onSelect={setSelectId}
          onHover={setHoverId}
          onClear={() => setHoverId(undefined)}
        />
      )}
    </div>
  );
});

import { request, parseErrorResponse, IRequestData } from "./axios";
import queryString from "query-string";

const getFriends = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(req.query);
    request()
      .get(`/searches/friends?${query}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRelations = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/relationships/relationsByUserId/${req.params.userId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProfileById = (id: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/profile/${id}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getAll`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDepartmentByUserId = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/${req.params.userId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/departments/`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addMemberToDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/addMemberToDepartment`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMemberInDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/getMemberInDepartment/${req.params.departmentId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const removeMemberToDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/removeMemberToDepartment`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const Service = {
  getRelations,
  getProfileById,
  getLocations,
  getDepartmentByUserId,
  createDepartment,
  updateDepartment,
  getFriends,
  addMemberToDepartment,
  getMemberInDepartment,
  removeMemberToDepartment,
};

export default Service;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { Relations } from "./Relations";
import css from "./NodeDetails.module.css";
import Service from "../../services/api";
import { GlobalContext } from "../../context/GlobalContext";
import { getAddress } from "../../utils";
import { getUrlImage } from "../../utils/common";

interface NodeDetailsProps {
  node: any;
  className?: string;
  myProfile: any;
  onSelect: (nodeId: string | undefined) => void;
  onHover: (nodeId: string) => void;
  onClear: () => void;
}

export const NodeDetails = memo(function NodeDetails({
  node,
  className,
  myProfile,
  ...props
}: NodeDetailsProps) {
  const [profile, setProfile] = useState(node);
  const { locations } = useContext(GlobalContext);

  console.log({
    myProfile,
    node
  });

  useEffect(() => {
    if (node) {
      getProfile(node.user_id);
    }
  }, [node]);

  const getProfile = async (userId: string) => {
    const res = await Service.getProfileById(userId);
    const { user }: any = res;
    if (user) {
      setProfile({
        ...node,
        ...user.profile,
      });
    }
  };

  const closeHandler = useCallback(() => props.onSelect(undefined), [props]);

  return (
    <section className={classNames(css.root, className)}>
      <header className={css.header}>
        <div className={css.avatar}>
          <img
            src={getUrlImage(profile.avatar)}
            alt=""
            width={40}
            height={40}
          />
        </div>
        <div className={css.title}>
          <h3>{profile.refer_code}</h3>
          <h3>{profile.full_name}</h3>
        </div>
        <button className={css.close} onClick={closeHandler}>
          &#10005;
        </button>
      </header>
      <div className={css.info}>
        <div className={css.info_detail}>
          <div className={css.info_title}>Phone:</div> {profile.phone}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Gender:</div> {profile.gender}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Nation:</div> {profile.nation?.name}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Career:</div> {profile.career?.name}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Field:</div> {profile.field?.name}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Religion:</div>{" "}
          {profile.religion?.name}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Hometown:</div>{" "}
          {getAddress(locations, profile.home_town)}
        </div>
        <div className={css.info_detail}>
          <div className={css.info_title}>Address:</div>{" "}
          {getAddress(locations, profile.location)}
        </div>
      </div>

      <Relations {...props} title="Parents" items={profile.parents} />
      <Relations {...props} title="Children" items={profile.children} />
      <Relations {...props} title="Siblings" items={profile.siblings} />
      <Relations {...props} title="Spouses" items={profile.spouses} />
    </section>
  );
});

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";

import "./styles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import Service from "services/api";
import { Button, Divider } from "semantic-ui-react";
import Members from "./Members";
import { getStringLimit } from "utils";

const node: any = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

const PreviewDeparment = () => {
  const defaultTree = [
    {
      title: "Deparment",
      children: [],
    },
  ];
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const [profile, setProfile] = useState(null);
  const [treeData, setTreeData] = useState(defaultTree);
  const navigate = useNavigate();
  const [nodeSelected, setNodeSelected] = useState(null);
  const memberRef = useRef(null);

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  useEffect(() => {
    if (profile) getDepartment();
  }, [profile]);

  const getData = async () => {
    try {
      const res: any = await Service.getProfileById(userId as string);
      if (res && res.user) {
        const _profile = {
          ...res.user,
          ...res.user.profile,
        };
        setProfile(_profile);
      }
    } catch (error) {}
  };

  const getDepartment = async () => {
    const _res: any = await Service.getDepartmentByUserId({
      params: {
        userId,
      },
    });
    if (_res && _res.value) {
      if (_res.value.length > 0) {
        const nodes = [
          {
            title: profile.full_name,
            children: _res.value,
          },
        ];
        setTreeData((prev) => nodes);
      } else setTreeData([]);
    } else {
      setTreeData([]);
    }
  };

  const handleBack = async () => {
    navigate(`/department?userId=${userId}&token=${token}`);
  };

  const NodeLabel = (props) => {
    const { className, nodeData }: any = props;
    return (
      <div className={className}>
        <div className="title">{getStringLimit(nodeData.title, 24)}</div>
        <Divider></Divider>
        <Button
          size="mini"
          basic
          color="blue"
          circular
          icon="view"
          className="view-member"
          onClick={(_) => {
            setNodeSelected(nodeData);
          }}
        >
          View member
        </Button>
      </div>
    );
  };

  return (
    <div className="preview">
      {nodeSelected && (
        <Members
          ref={memberRef}
          node={nodeSelected}
          setNodeSelected={setNodeSelected}
        />
      )}
      <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
        {token && (
          <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
            <Divider></Divider>
            <Button
              size="mini"
              color="blue"
              disabled={!userId ? true : false}
              onClick={handleBack}
            >
              Back
            </Button>
            <Divider></Divider>
          </div>
        )}

        <Tree
          data={treeData}
          // nodeSvgShape={svgSquare}
          nodeSvgShape={node}
          pathFunc="step"
          separation={{ siblings: 1.4, nonSiblings: 1 }}
          orientation="vertical"
          translate={{ x: 400, y: 100 }}
          allowForeignObjects={true}
          nodeLabelComponent={{
            render: <NodeLabel className="node" />,
            foreignObjectWrapper: {
              width: 220,
              height: 200,
              y: -50,
              x: -100,
            },
          }}
          initialDepth={0.02}
        />
      </div>
    </div>
  );
};

export default PreviewDeparment;

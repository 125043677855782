const getAddress = (locations: any, address: any) => {
  let provinceName;
  let districtName;
  let wardName;
  const _provinces = locations?.filter(
    (pro: any) => pro.parent === address?.country
  );

  provinceName = _provinces.find(
    (el: { _id: any }) => el._id === address?.province
  )?.name;

  if (address?.province) {
    const _districts: any = locations?.filter(
      (pro: any) => pro.parent === address.province
    );
    districtName = _districts.find(
      (el: { _id: any }) => el._id === address.district
    )?.name;
  }
  if (address?.district) {
    const _wards: any = locations?.filter(
      (pro: any) => pro.parent === address.district
    );
    wardName = _wards.find(
      (el: { _id: any }) => el._id === address?.ward
    )?.name;
  }

  return `${address?.detail ? `${address?.detail}, ` : ""}${
    wardName ? `${wardName}, ` : ""
  }${districtName ? `${districtName}, ` : ""}${
    provinceName ? `${provinceName}` : ""
  }`;
};

const getUrlImage = (img) => {
  if (!img) return "https://vdiarybook.net/assets/images/VDB-64.png";
  const newImg = img.replace("uploads/", "/");
  if (
    String(newImg).includes("http://") ||
    String(newImg).includes("https://")
  ) {
    return newImg;
  }
  return `${
    process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
  }/api/files/${newImg}`;
};

const getStringLimit = (name: string, limit = 10) => {
  if (name.length > limit) return `${String(name).slice(0, limit)}...`;
  return name;
};

export { getAddress, getUrlImage, getStringLimit };

import { Routes, Route } from "react-router-dom";
import Family from "./pages/family";
import Department from "./pages/department";
import PreviewDeparment from "pages/department/Preview";

export default function App() {
  return (
    <Routes>
      <Route path="/family" element={<Family />} />
      <Route path="/department" element={<Department />} />
      <Route path="/preview" element={<PreviewDeparment />} />
    </Routes>
  );
}

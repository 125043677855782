import Avatar from "../assets/avatar.png";

export const getUrlImage = (img: string) => {
  if (!img) return Avatar;
  const newImg = img.replace("uploads/", "/");
  if (
    String(newImg).includes("http://") ||
    String(newImg).includes("https://")
  ) {
    return newImg;
  }
  return `${
    process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
  }/api/files/${newImg}`;
};

// export const getRealtion = (my: any, node: any) => {
//   if (my._id === node.user_id) return "";
//   const { parents, siblings, spouses, children } = node;

//   if(parents && parents.some((p :any) => p.refer_code === my.refer_code)){
//     return
//   }
// };

import { useState, createContext } from "react";
import Service from "../services/api";

type GlobalContext = {
  token?: string;
  user?: any;
  careers?: any;
  getCareers?: any;
  locations?: any;
  getLocations?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext<GlobalContext>({} as GlobalContext);

export const MasterProvider = (props: any) => {
//   const [careers, setCareers] = useState([]);
  const [locations, setLocations] = useState([]);

  const getCareers = () => {
    // CareerService.getAll().then((res: any) => {
    //   setCareers(res || []);
    // });
  };

  const getLocations = () => {
    Service.getLocations().then((res: any) => {
      setLocations(res || []);
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        // careers,
        locations,
        getLocations,
        getCareers,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </GlobalContext.Provider>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Card, Image } from "semantic-ui-react";
import Service from "services/api";
import { getStringLimit, getUrlImage } from "utils";

const Member = ({ m, getMembers, isAuth }) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async (member) => {
    try {
      setLoading(true);
      await Service.removeMemberToDepartment({
        body: m,
      });
      getMembers();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="item">
      <div>
        <Image src={getUrlImage(m.member.avatar)} avatar />
        <span className="fullName">{m.member.full_name}</span>
      </div>
      {isAuth && (
        <div>
          <Button
            size="mini"
            basic
            color="red"
            circular
            icon="trash"
            loading={loading}
            onClick={(e) => {
              handleRemove(m);
            }}
          />
        </div>
      )}
    </div>
  );
};

const Members = ({ node, setNodeSelected, isAuth = false }, ref) => {
  const [members, setMembers] = useState([]);

  useImperativeHandle(ref, () => ({
    reload: () => {
      getMembers();
    },
  }));

  useEffect(() => {
    if (node && node._id) {
      getMembers();
    } else setMembers([]);
  }, [node]);

  const getMembers = async () => {
    const res: any = await Service.getMemberInDepartment({
      params: {
        departmentId: node._id,
      },
    });
    if (res && res.length > 0) {
      setMembers(res);
    } else setMembers([]);
  };

  return (
    <>
      <div className="members">
        <Card>
          <Card.Content>
            <Card.Header>
              <div className="title">
                <span className="name">
                  {node ? getStringLimit(node.title, 15) : "Department"}
                </span>
                <span className="amount"> ({members.length} members)</span>
              </div>
              <Button
                size="tiny"
                basic
                color="red"
                circular
                icon="close"
                onClick={(e) => {
                  setNodeSelected(null);
                }}
              />
            </Card.Header>
            <Card.Description>
              {members.map((m, key) => (
                <Member
                  m={m}
                  key={key}
                  getMembers={getMembers}
                  isAuth={isAuth}
                />
              ))}
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    </>
  );
};

export default forwardRef(Members);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SortableTree, {
  TreeItem,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import { Button, Divider, Dropdown, Input, Modal } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Service from "services/api";
import { v4 as uuidv4 } from "uuid";
import { getUrlImage } from "utils";
import "./styles.scss";
import Members from "./Members";

const View = () => {
  const defaultData = [
    {
      _id: uuidv4(),
      title: "Department 1",
    },
    {
      _id: uuidv4(),
      title: "Deparment 2",
    },
  ];

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [state, setState] = useState({
    searchString: "",
    searchFocusIndex: 0,
    currentNode: {},
  });
  const [treeData, setTreeData] = useState<TreeItem[]>(defaultData);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [nodeSelected, setNodeSelected] = useState(null);
  const [friends, setFriends] = useState([]);
  const [memberId, setMemberId] = useState(null);
  const [loadingAddMember, setLoadingAddMember] = useState(false);
  const memberRef = useRef(null);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token_auth", token);
    }
  }, [token]);

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  const getData = async () => {
    try {
      const res: any = await Service.getDepartmentByUserId({
        params: {
          userId,
        },
      });
      if (res && res.value) {
        setIsEdit(true);
        if (res.value.length > 0) setTreeData(res.value);
        else setTreeData(defaultData);
      } else {
        setIsEdit(false);
      }
    } catch (error) {
      setIsEdit(false);
      setTreeData(defaultData);
    }
  };

  const expandAndCollapse = (expanded) => {
    setTreeData((prev) =>
      toggleExpandedForAll({
        treeData: prev,
        expanded,
      })
    );
  };

  const updateTreeData = (treeData) => {
    setTreeData(treeData);
  };

  const removeNode = (path) => {
    setTreeData(
      removeNodeAtPath({
        treeData: treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      })
    );
  };

  const selectThis = (node, path) => {
    setState((prev) => ({ ...prev, currentNode: node, path: path }));
  };

  const insertNewNode = () => {
    setTreeData(
      insertNode({
        treeData: treeData,
        depth: 0,
        minimumTreeIndex: treeData.length,
        newNode: { _id: uuidv4(), title: "", children: [] },
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleSubmit = async () => {
    try {
      if (userId) {
        setLoading(true);
        if (isEdit) {
          await Service.updateDepartment({
            body: {
              user_id: userId,
              value: treeData,
            },
          });
        } else {
          const res = await Service.createDepartment({
            body: {
              user_id: userId,
              value: treeData,
            },
          });
          if (res) getData();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePreview = async () => {
    navigate(`/preview?userId=${userId}&token=${token}`);
  };

  const searchModal = async (e) => {
    try {
      const res: any = await Service.getFriends({
        query: {
          search: e.target.value,
          limit: 10,
          page: 1,
          userId: userId,
        },
      });
      if (res && res.docs) {
        setFriends(res.docs);
      }
    } catch (error) {
      setFriends([]);
    }
  };

  const handleAddUser = async () => {
    try {
      if (memberId && nodeSelected) {
        setLoadingAddMember(true);
        await Service.addMemberToDepartment({
          body: {
            member_id: memberId,
            department_id: nodeSelected._id,
            user_id: userId,
          },
        });
        memberRef.current.reload();
        setLoadingAddMember(false);
        setOpen(false);
      }
    } catch (error) {
      setLoadingAddMember(false);
    }
  };

  const ModalAddUser = (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Add User</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Select Friend"
          onSearchChange={searchModal}
          fluid
          search
          selection
          onChange={(_, { value }) => {
            setMemberId(value);
          }}
          options={friends.map((f) => ({
            key: f._id,
            text: `${f.full_name} (${f.refer_code})`,
            value: f._id,
            image: { avatar: true, src: getUrlImage(f.avatar) },
          }))}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
            setMemberId("");
          }}
        >
          Cancel
        </Button>
        <Button
          content="Confirm"
          labelPosition="right"
          icon="checkmark"
          loading={loadingAddMember}
          onClick={() => handleAddUser()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );

  return (
    <div style={{ height: 800 }} className="">
      {ModalAddUser}
      {nodeSelected && (
        <Members
          ref={memberRef}
          node={nodeSelected}
          setNodeSelected={setNodeSelected}
          isAuth={token ? true : false}
        />
      )}
      <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
        <Divider></Divider>
        <Button
          size="mini"
          color="blue"
          disabled={!userId ? true : false}
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          size="mini"
          color="blue"
          disabled={!userId ? true : false}
          onClick={handlePreview}
        >
          Preview
        </Button>
        <Button
          size="mini"
          color="blue"
          onClick={() => expandAndCollapse(true)}
        >
          Expand all
        </Button>
        <Button
          size="mini"
          color="blue"
          onClick={() => expandAndCollapse(false)}
        >
          Collapse all
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Input
          size="mini"
          placeholder="Search"
          value={state.searchString}
          onChange={(event) =>
            setState((prev) => ({ ...prev, searchString: event.target.value }))
          }
        />
      </div>
      <Divider></Divider>
      <SortableTree
        searchQuery={state.searchString}
        treeData={treeData}
        searchFocusOffset={state.searchFocusIndex}
        onChange={updateTreeData}
        generateNodeProps={({ node, path }) => ({
          title: (
            <form
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectThis(node, path);
              }}
            >
              <input
                style={{ fontSize: "1rem", width: 200 }}
                value={node.title}
                onChange={(event) => {
                  const title = event.target.value;
                  setTreeData((prev) =>
                    changeNodeAtPath({
                      treeData: prev,
                      path,
                      getNodeKey,
                      newNode: { ...node, title },
                    })
                  );
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <Button
                size="mini"
                basic
                color="blue"
                circular
                icon="add"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  insertNewNode();
                }}
              />
              <Button
                size="mini"
                basic
                color="red"
                circular
                icon="trash"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeNode(path);
                }}
              />
              <Button
                size="mini"
                basic
                color="green"
                circular
                icon="user"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNodeSelected(node);
                  setOpen(true);
                }}
              />
              <Button
                size="mini"
                basic
                color="twitter"
                circular
                icon="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNodeSelected(node);
                }}
              />
            </form>
          ),
        })}
      />
    </div>
  );
};

export default View;

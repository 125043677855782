import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MasterProvider } from "./context/GlobalContext";
import App from "./App";
import 'semantic-ui-css/semantic.min.css'

createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <MasterProvider>
      <App />
    </MasterProvider>
  </BrowserRouter>
);

import React, { useCallback } from "react";
import classNames from "classnames";
import css from "./FamilyNode.module.css";

import { getUrlImage } from "../../utils/common";

interface FamilyNodeProps {
  node: any;
  isRoot: boolean;
  isHover?: boolean;
  profile: any;
  style?: React.CSSProperties;
  onClick: (id: string) => void;
  onSubClick: (id: string) => void;
}

export const FamilyNode = React.memo(function FamilyNode({
  node,
  isRoot,
  isHover,
  style,
  onClick,
  onSubClick,
}: FamilyNodeProps) {
  const clickHandler = useCallback(() => onClick(node.id), [node.id, onClick]);
  const clickSubHandler = useCallback(
    () => onSubClick(node.id),
    [node.id, onSubClick]
  );

  return (
    <div className={css.root} style={style}>
      <div
        className={classNames(
          css.inner,
          css[String(node.gender || "").toLowerCase()],
          isRoot && css.isRoot,
          isHover && css.isHover
        )}
        onClick={clickHandler}
      >
        <div className={css.id}>
          <div className={css.avatar}>
            <img
              className={css.img}
              src={getUrlImage(node.avatar)}
              alt=""
              width={30}
              height={30}
            />
          </div>
          <div className={css.refer_code}>{node.refer_code}</div>
          <div className={css.full_name}>{node.full_name}</div>
        </div>
      </div>
      {node.hasSubTree && (
        <div
          className={classNames(
            css.sub,
            css[String(node.gender || "").toLowerCase()]
          )}
          onClick={clickSubHandler}
        />
      )}
    </div>
  );
});
